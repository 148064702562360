
  import { Action, Getter } from "vuex-class";
  import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
  import { FormBase, FormInput, FormError, FormSubmit, FormOriginInput, FormYesNo, FormSelect, FormDate, FormMoney } from "@/components/forms";
  import { HasFormErrors } from "@/mixins/has-form-errors";
  import { WorkStatus } from "@/store/modules/activity.store";
  import { activityIsDossier } from "@/utils/model-helpers";
  import { bus } from "@/main";
  import { format, parse } from "fecha";

  interface SelectOption {
    label: string;
    value: string;
  }

  @Component({
    components: {
      LayoutModal: () => import("@/layouts/components/modal.vue"),
      ActivityTransitionFailedModal: () => import("@/components/modals/activity-transition-failed-modal.vue"),
      FormBase,
      FormDate,
      FormInput,
      FormMoney,
      FormError,
      FormYesNo,
      FormOriginInput,
      FormSelect,
      FormSubmit,
    },
  })
  export default class DossierTabGeneralSettings extends Mixins(HasFormErrors) {
    @Getter("activity/viewing") activity!: Activity;
    @Action("activity/patch") patchActivity!: (payload: ActivityPatchPayload) => Promise<Activity>;
    @Action("property/patch") patchProperty!: (payload: PropertyPatchPayload) => Promise<Property>;

    @Ref() form!: FormClass;

    payload: ActivityPatchPayload = {
      origin: {
        origin: null,
      },
      id: NaN,
      name: "",
      out_bo_date: null,
      deed_execution_date: null,
      compromis_signed_at: null,
      fixed_commission: null,
      relative_commission: 3,
      commission_vat_inclusive: false,
      sold_for: null,
      commercial_gesture: null,
      commercial_gesture_vat_inclusive: false,
    };

    address: Address = {
      street: "",
      number: "",
      additional: "",
      city: "",
      zip: "",
      country: "BE",
    };

    country = {
      value: "BE",
      label: "België",
    };

    countries: SelectOption[] = [
      {
        value: "BE",
        label: "België",
      },
    ];

    sold_for_price: null | number = null;

    relative_commission_incl_vat = false;

    fixed_commission_incl_vat = false;

    get isDossier() {
      return activityIsDossier(this.activity);
    }

    get statuses() {
      if (!this.isDossier) {
        return [WorkStatus.new, WorkStatus.contact, WorkStatus.estimate, WorkStatus.followUp, WorkStatus.followUpLT, WorkStatus.won, WorkStatus.lost].map((s) => {
          return { label: this.$t(`property.work_status.${s}`), value: s };
        });
      }

      if (process.env.NODE_ENV === "development") {
        return [
          WorkStatus.won,
          WorkStatus.newDossier,
          WorkStatus.startUp,
          WorkStatus.published,
          WorkStatus.firstOffer,
          WorkStatus.sold,
          WorkStatus.archived,
          WorkStatus.done,
          WorkStatus.onHold,
        ].map((s) => {
          return { label: this.$t(`property.work_status.${s}`), value: s };
        });
      }

      return [WorkStatus.newDossier, WorkStatus.startUp, WorkStatus.published, WorkStatus.firstOffer, WorkStatus.sold, WorkStatus.archived, WorkStatus.done, WorkStatus.onHold].map(
        (s) => {
          return { label: this.$t(`property.work_status.${s}`), value: s };
        },
      );
    }

    get isRelative() {
      return this.payload.fixed_commission === null && this.payload.relative_commission !== null;
    }

    get isFixed() {
      return this.payload.relative_commission === null && this.payload.fixed_commission !== null;
    }

    mounted() {
      this.payload.id = this.activity.id;
      this.payload.work_status = this.activity.work_status;
      this.payload.out_bo_date = this.activity.out_bo_date ? format(new Date(this.activity.out_bo_date), "YYYY-MM-DD[T]HH:mm:ssZ") : null;
      this.payload.deed_execution_date = this.activity.deed_execution_date ? format(new Date(this.activity.deed_execution_date), "YYYY-MM-DD[T]HH:mm:ssZ") : null;
      this.payload.compromis_signed_at = this.activity.compromis_signed_at ? format(new Date(this.activity.compromis_signed_at), "YYYY-MM-DD[T]HH:mm:ssZ") : null;
      this.payload.commission_vat_inclusive = this.activity.commission_vat_inclusive;
      this.payload.fixed_commission = this.activity.fixed_commission;
      this.payload.relative_commission = this.activity.relative_commission;
      this.payload.origin = this.activity.origin ?? { origin: null };
      this.payload.commercial_gesture = this.activity.commercial_gesture;
      this.payload.commercial_gesture_vat_inclusive = false;
      this.sold_for_price = this.activity.property_sale?.sold_for;

      if (this.payload.fixed_commission !== null) {
        this.fixed_commission_incl_vat = this.payload.commission_vat_inclusive ?? false;
      }

      if (this.payload.relative_commission !== null) {
        this.relative_commission_incl_vat = this.payload.commission_vat_inclusive ?? false;
      }

      this.address = JSON.parse(JSON.stringify(this.activity.property_address));
      this.payload.name = this.activity.name;

      if (!this.sold_for_price && this.activity.property_sale?.approved_bid_from) {
        this.sold_for_price = this.activity.property_sale.approved_bid_from;
      }
    }

    async submit(form: FormClass) {
      try {
        if (this.isFixed) {
          this.payload.commission_vat_inclusive = this.fixed_commission_incl_vat;
        } else if (this.isRelative) {
          this.payload.commission_vat_inclusive = this.relative_commission_incl_vat;
        }

        if (this.sold_for_price !== this.activity.property_sale?.approved_bid_from) {
          this.payload.sold_for = this.sold_for_price;
        }

        await this.patchActivity(this.payload);

        await this.patchProperty({ property_id: this.activity.property_id as number, address: this.address });

        this.$toast.open({ message: this.$t("views.dossier.update_success") as string, type: "success", position: "bottom-right" });
      } catch (e: any) {
        this.errorResponse = this.formatErrors(e);

        if (e.errors && e.errors[0] && e.errors[0].message && e.errors[0].message === "transition_exception") {
          bus.$emit("show-transition-failed", { status: e.errors[0].params, activityId: this.activity.id });
        }
        if (e.errors && e.errors[0] && e.errors[0].message && e.errors[0].message === "validation.can_transition") {
          bus.$emit("show-transition-failed", { status: [e.errors[0].message], activityId: this.activity.id });
        }
      } finally {
        form.reset();
      }
    }

    handleRelativeCommissionChanged() {
      this.payload.fixed_commission = null;
    }

    handleFixedCommissionChanged() {
      this.payload.relative_commission = null;
    }

    handleAddressChanged() {
      this.payload.name = `${this.address.zip} ${this.address.city} - ${this.address.street} ${this.address.number}${this.address.additional ? " " + this.address.additional : ""}`;
    }
  }
